<template>

    <el-row :gutter="40">
        <el-col :span="8">
            <el-card shadow="hover">
                <div class="text-center">

                    <el-upload
                        class="avatar-uploader"
                        accept="image/jpeg,image/jpg,image/gif,image/png"
                        :data="avatarData"
                        :action="STORAGE_API_BASE_URL"
                        :drag="true"
                        :multiple="false"
                        :show-file-list="false"
                        :on-error="handleAvatarError"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload">

                        <el-avatar :size="125" :src="avatar" v-if="avatar"></el-avatar>
                        <el-avatar :size="125" v-else> {{ getNameInitials((userData && userData.name) || '') }} </el-avatar>

                    </el-upload>

                    <template v-if="avatar">
                        <el-divider></el-divider>
                        <el-button type="link" @click="clearAvatar()">{{ $t('clear') }}</el-button>
                    </template>

                </div>
            </el-card>
        </el-col>
        <el-col :span="16">
            <el-card shadow="hover">
                <template #header>
                    <div class="card-header">
                        <span>{{ $t('accountInfo') }}</span>
                        <el-button class="button" type="primary" @click="updateAccountInfo()">{{ $t('update') }}</el-button>
                    </div>
                </template>
                <div class="text-center">

                    <el-form
                        label-position="left"
                        label-width="150px"
                        ref="accountForm"
                        :model="account">

                        <el-form-item prop="uid" :label="$t('id')">
                            <el-input
                                :placeholder="$t('id')"
                                v-model="account.uid"/>
                            <div class="el-form-item__error" v-if="!isUidValid">{{ $t('idUnavailable') }}</div>
                        </el-form-item>

                        <el-form-item prop="name" :label="$t('name')">
                            <el-input
                                :placeholder="$t('name')"
                                v-model="account.name"/>
                        </el-form-item>

                        <el-form-item prop="password" :label="$t('password')">
                            <el-input
                                :key="passwordType"
                                :type="passwordType"
                                v-model.trim="account.password"
                                prefix-icon="lock"
                                auto-complete="on"
                                ref="password"
                                tabindex="2"/>
                            <span class="show-pwd" @click="showPwd">
                                <font-awesome-icon :icon="passwordType === 'password' ? 'eye-slash' : 'eye'" />
                            </span>
                        </el-form-item>

                    </el-form>

                    <el-form
                        v-if="userRole == Roles.TEACHER"
                        label-position="left"
                        label-width="150px"
                        ref="teacherInfoForm"
                        :model="teacherInfo">

                        <el-form-item prop="title" :label="$t('civility')">
                            <el-select
                                v-model="teacherInfo.title"
                                :placeholder="$t('select')"
                                :no-data-text="$t('noData')"
                                :no-match-text="$t('noMatchingData')"
                                style="width: 100%">
                                <el-option
                                    v-for="item in titles"
                                    :key="item.id"
                                    :value="item.id"
                                    :label="item.label">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item prop="phone" :label="$t('phone')">
                            <el-input
                                type="tel"
                                :placeholder="$t('phone')"
                                v-model="teacherInfo.phone"/>
                        </el-form-item>

                        <el-form-item prop="email" :label="$t('email')">
                            <el-input
                                type="email"
                                :placeholder="$t('email')"
                                v-model="teacherInfo.email"/>
                        </el-form-item>

                    </el-form>

                    <el-form
                        v-if="userRole == Roles.STUDENT"
                        label-position="left"
                        label-width="150px"
                        ref="studentInfoForm"
                        :model="studentInfo">

                        <el-form-item prop="sex" :label="$t('sex')">
                            <el-select
                                :disabled="true"
                                v-model="studentInfo.sex"
                                :placeholder="$t('select')"
                                :no-data-text="$t('noData')"
                                :no-match-text="$t('noMatchingData')"
                                style="width: 100%">
                                <el-option
                                    v-for="item in sexes"
                                    :key="item.id"
                                    :value="item.id"
                                    :label="item.label">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item prop="birthday" :label="$t('birthday')">
                            <el-date-picker
                                type="date"
                                :disabled="true"
                                :placeholder="$t('birthday')"
                                v-model="studentInfo.birthday"
                                style="width: 100%"/>
                        </el-form-item>

                        <el-form-item prop="birthplace" :label="$t('birthplace')">
                            <el-input
                                :disabled="true"
                                :placeholder="$t('birthplace')"
                                v-model="studentInfo.birthplace"
                                style="width: 100%"/>
                        </el-form-item>

                        <el-form-item prop="nationality" :label="$t('nationality')">
                            <el-input
                                :disabled="true"
                                :placeholder="$t('nationality')"
                                v-model="studentInfo.nationality"
                                style="width: 100%"/>
                        </el-form-item>

                        <el-form-item prop="idnum" :label="$t('idNumber')">
                            <el-input
                                :disabled="true"
                                :placeholder="$t('idNumber')"
                                v-model="studentInfo.idnum"
                                style="width: 100%"/>
                        </el-form-item>

                        <el-form-item prop="parent" :label="$t('parent')">
                            <el-select
                                filterable
                                :disabled="true"
                                v-model="studentInfo.parent"
                                :placeholder="$t('select')"
                                :no-data-text="$t('noData')"
                                :no-match-text="$t('noMatchingData')"
                                style="width: 100%">
                                <el-option
                                    v-for="item in parents"
                                    :key="item.id"
                                    :value="item.id"
                                    :label="item.name">
                                </el-option>
                            </el-select>
                        </el-form-item>

                    </el-form>

                    <el-form
                        v-if="userRole == Roles.PARENT"
                        label-position="left"
                        label-width="150px"
                        ref="parentInfoForm"
                        :model="parentInfo">

                        <el-form-item prop="phone" :label="$t('phone')">
                            <el-input
                                type="tel"
                                :placeholder="$t('phone')"
                                v-model="parentInfo.phone"/>
                        </el-form-item>

                        <el-form-item prop="email" :label="$t('email')">
                            <el-input
                                type="email"
                                :placeholder="$t('email')"
                                v-model="parentInfo.email"/>
                        </el-form-item>

                        <el-form-item prop="nationality" :label="$t('nationality')">
                            <el-input
                                style="width: 100%"
                                :placeholder="$t('nationality')"
                                v-model="parentInfo.nationality"/>
                        </el-form-item>

                        <el-form-item prop="job" :label="$t('job')">
                            <el-input
                                style="width: 100%"
                                :placeholder="$t('job')"
                                v-model="parentInfo.job"/>
                        </el-form-item>

                        <el-form-item prop="bp" :label="$t('postalBank')">
                            <el-input
                                style="width: 100%"
                                :placeholder="$t('postalBank')"
                                v-model="parentInfo.bp"/>
                        </el-form-item>

                    </el-form>

                </div>
            </el-card>
        </el-col>
    </el-row>

</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

import { STORAGE_API_BASE_URL } from '@/utils/constants'
import { getNameInitials } from "@/utils/functions"

import { Titles, Roles, Sexes } from '@/utils/enums'

import { formatDate } from '@/utils/chronos'

import sanitizer from 'string-sanitizer'

import accessManager from '@/mixins/access-manager'

export default {
    name: 'AccountSettings',
    mixins: [accessManager],
    data() {
        return {
            Roles,
            sexes: [],
            titles: [],
            parents: [],
            account: {
                uid: '',
                name: '',
                password: ''
            },
            teacherInfo: {
                id: 0,
                title: '',
                phone: '',
                email: ''
            },
            studentInfo: {
                id: 0,
                sex: '',
                idnum: '',
                parent: '',
                birthday: '',
                birthplace: '',
                nationality: '',
            },
            parentInfo: {
                id: 0,
                bp: '',
                job: '',
                email: '',
                phone: '',
                nationality: '',
            },
            avatarData:{
                folder: 'avatars'
            },
            avatar: null,
            isUidValid: true,
            passwordType: 'password',
            STORAGE_API_BASE_URL
        }
    },
    watch:{
        'account.uid': 'checkUid'
    },
    computed: {
        ...mapState({
            userData : state => state.session.userData
        })
    },
    created() {

        var self = this

        this.$_.forIn(Titles, function(value, key) {

            self.titles.push(value)

        })

        this.$_.forIn(Sexes, function(value, key) {

            self.sexes.push(value)

        })

        this.avatar = this.userData.avatar
        this.account.uid = this.userData.uid
        this.account.name = this.userData.name

        this.fetchData()

    },
    methods: {
        ...mapMutations({
            updateUserData: 'session/updateUserData'
        }),
        ...mapActions({
            fetchUserDB: 'user/fetchUserDB',
            fetchParentsDB: 'parents/fetchParentsDB',
            fetchTeachersDB: 'teachers/fetchTeachersDB',
            fetchStudentsDB: 'students/fetchStudentsDB',
            getUserByCredentials: 'authentication/getUserByCredentials'
        }),
        getNameInitials,
        showPwd() {

            this.passwordType = this.passwordType === 'password' ? '' : 'password'

            this.$nextTick(() => {
                this.$refs.password.focus()
            })

        },
        async checkUid(){

            this.account.uid = sanitizer.sanitize(this.account.uid.toLowerCase())

            let task = await this.fetchUserDB({
                action: 'checkUserExists',
                params: {
                    uid: this.account.uid
                }
            })

            this.isUidValid = this.account.uid === '' || (this.userData.uid === this.account.uid) || (task.success && !task.data)

        },
        async updateAccountInfo(){

            if (!this.account.uid){
                this.$message.error(this.$t('idRequired'))
                return
            }

            if (!this.account.name){
                this.$message.error(this.$t('nameRequired'))
                return
            }

            if (this.account.password && this.account.password.length < 6){
                this.$message.error(this.$t('shortPassword'))
                return
            }

            await this.checkUid()

            if (!this.isUidValid) {
                this.$message.error(this.$t('idUnavailable'))
                return
            }

            if (this.account.password){

                this.$prompt(this.$t('provideCurrentPassword'), this.$t('confirmation'), {
                    inputPlaceholder: this.$t('password'),
                    confirmButtonText: this.$t('confirm'),
                    cancelButtonText: this.$t('cancel'),
                    inputType: 'password'
                })
                .then(async ({ value }) => {

                    if (value){

                        let resp = await this.getUserByCredentials({
                            username: this.userData.uid,
                            password: value
                        })

                        if (resp.success){

                            if (resp.data.username && resp.data.password && resp.data.user){

                                 this.executeUpdate(true)

                            } else {

                                this.$message.error(this.$t('wrongPassword'))

                            }

                        }else{

                            this.$message.error(this.$t('anErrorOccured'))

                        }

                    }

                })
                .catch(() => {

                })

            }else{

                this.executeUpdate()

            }

        },
        async executeUpdate(includePassword = false){

            let showSuccessMessage = false

            switch (this.userRole) {

                case Roles.TEACHER:
                    let newTeacherData = {}

                    newTeacherData['name'] = this.account.name
                    newTeacherData['title'] = this.teacherInfo.title
                    newTeacherData['email'] = this.teacherInfo.email
                    newTeacherData['phone'] = this.teacherInfo.phone

                    await this.fetchTeachersDB({
                        action: 'editTeacher',
                        params: {
                            id: this.teacherInfo.id,
                            data: newTeacherData
                        }
                    })

                    showSuccessMessage = true

                    break

                case Roles.PARENT:
                    let newParentData = {}

                    newParentData['bp'] = this.parentInfo.bp
                    newParentData['name'] = this.account.name
                    newParentData['job'] = this.parentInfo.job
                    newParentData['phone'] = this.parentInfo.phone
                    newParentData['email'] = this.parentInfo.email
                    newParentData['nationality'] = this.parentInfo.nationality

                    await this.fetchParentsDB({
                        action: 'editParent',
                        params: {
                            id: this.parentInfo.id,
                            data: newParentData
                        }
                    })

                    showSuccessMessage = true

                    break

                case Roles.STUDENT:
                    let newStudentData = {}

                    newStudentData['sex'] = this.studentInfo.sex
                    newStudentData['idnum'] = this.studentInfo.idnum
                    newStudentData['parent'] = this.studentInfo.parent
                    newStudentData['birthday'] = this.studentInfo.birthday ? formatDate(this.studentInfo.birthday, {outPattern: 'YYYY-MM-DD'}) : null
                    newStudentData['birthplace'] = this.studentInfo.birthplace
                    newStudentData['nationality'] = this.studentInfo.nationality

                    await this.fetchStudentsDB({
                        action: 'editStudent',
                        params: {
                            id: this.studentInfo.id,
                            data: newStudentData
                        }
                    })

                    showSuccessMessage = true

                    break

            }

            let newData = {}

            if (this.account.uid !== this.userData.uid) newData['uid'] = this.account.uid

            if (this.account.name !== this.userData.name) newData['name'] = this.account.name

            if (includePassword) newData['password'] = this.account.password

            if (!this.$_.isEmpty(newData)){

                let task = await this.fetchUserDB({
                    action: 'editUser',
                    params: {
                        id: this.userData.id,
                        data: newData
                    }
                })

                if (task.success) {

                    this.$message.success(this.$t('success'))

                    let user = this.userData

                    user.uid = this.account.uid
                    user.name = this.account.name

                    this.updateUserData(user)

                    if (includePassword) this.account.password = ''

                } else {
                    this.$message.error(this.$t('anErrorOccured'))
                }

            } else {

                if (showSuccessMessage) this.$message.success(this.$t('success'))

            }

        },
        async updateAvatar(){

            let task = await this.fetchUserDB({
                action: 'editUser',
                params: {
                    id: this.userData.id,
                    data: {
                        avatar: this.avatar
                    }
                }
            })

            if (task.success) {

                this.$message.success(this.$t('success'))

                let user = this.userData

                user.avatar = this.avatar

                this.updateUserData(user)

            } else {
                this.$message.error(this.$t('anErrorOccured'))
            }

        },
        handleAvatarError(err, file) {

            this.$message.error(this.$t('anErrorOccured'))

        },
        async handleAvatarSuccess(res, file) {

            this.avatar = STORAGE_API_BASE_URL + 'root/' + this.avatarData.folder + '/' + file.name

            this.updateAvatar()

        },
        beforeAvatarUpload(file){

            const isImg = file.type === 'image/jpeg' || 'image/jpg' || 'image/gif' || 'image/png'
            const sizeLessThan3M = file.size / 1024 / 1024 < 3

            if (!isImg) this.$message.error(this.$t('uploadImageFormatWarning'))

            if (!sizeLessThan3M) this.$message.error(this.$t('uploadFileSizeWarning', [3]))

            return isImg && sizeLessThan3M

        },
        clearAvatar(){

            this.avatar = null

            this.updateAvatar()

        },
        async fetchData() {

            let resp2 = await this.fetchTeachersDB({
                action: 'getTeacherByUser',
                params: {
                    user: this.userData.id,
                    schoolyear: this.currentSchoolyear
                }
            })

            if (resp2.success && resp2.data) {

                this.teacherInfo.id = resp2.data.id
                this.teacherInfo.title = parseInt(resp2.data.title)
                this.teacherInfo.phone = resp2.data.phone
                this.teacherInfo.email = resp2.data.email

            }

            let resp3 = await this.fetchStudentsDB({
                action: 'getStudentByUser',
                params: {
                    user: this.userData.id,
                    schoolyear: this.currentSchoolyear
                }
            })

            if (resp3.success && resp3.data) {

                this.studentInfo.id = resp3.data.id
                this.studentInfo.sex = parseInt(resp3.data.title)
                this.studentInfo.idnum = resp3.data.idnum
                this.studentInfo.parent = resp3.data.parent == '0' ? '' : resp3.data.parent
                this.studentInfo.birthplace = resp3.data.birthplace
                this.studentInfo.nationality = resp3.data.nationality

                if (resp3.data.birthday){

                    let bd = resp3.data.birthday.split('-')
                    this.studentInfo.birthday = new Date(bd[0], parseInt(bd[1]) - 1, bd[2])

                } else {
                    this.studentInfo.birthday = null
                }

            }

            let resp4 = await this.fetchParentsDB({
                action: 'getParentByUser',
                params: {
                    user: this.userData.id,
                    schoolyear: this.currentSchoolyear
                }
            })

            if (resp4.success && resp4.data) {

                this.parentInfo.id = resp4.data.id
                this.parentInfo.bp = resp4.data.bp
                this.parentInfo.job = resp4.data.job
                this.parentInfo.email = resp4.data.email
                this.parentInfo.phone = resp4.data.phone
                this.parentInfo.nationality = resp4.data.nationality

            }

            let resp1 = await this.fetchParentsDB({
                action: 'getParents',
                params: {
                    schoolyear: this.currentSchoolyear
                }
            })

            if (resp1.success && resp1.data) this.parents = resp1.data

        }
    },
}
</script>

<style scoped>

    .el-avatar {
        font-size: 65px;
    }

    ::v-deep(.el-upload-dragger) {
        width: unset;
        height: unset;
        justify-content: center;
        align-items: center;
        display: flex;
        padding: 5px;
    }

    .card-header, .card-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .show-pwd {
        position: absolute;
        right: 10px;
        font-size: 14px;
        color: gray;
        cursor: pointer;
        user-select: none;
    }

</style>